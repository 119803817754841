import React from 'react';
import './FavoriteAdsPage.css';

function FavoriteAdsPage() {
    return (
        <div className="favorite-ads-page">
            <h1>Favori İlanlar</h1>
            <p>Burada kullanıcı favori ilanları yer alacak.</p>
        </div>
    );
}

export default FavoriteAdsPage;
