import React from 'react';
import './TurbolarPage.css';

function TurbolarPage() {
    return (
        <div className="turbolar-page">
            <h1>Turbolar</h1>
            <p>Burada kullanıcı turbolar ile ilgili bilgiler yer alacak.</p>
        </div>
    );
}

export default TurbolarPage;
