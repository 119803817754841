import React from 'react';
import './MessagesPage.css';

function MessagesPage() {
    return (
        <div className="messages-page">
            <h1>Mesajlar</h1>
            <p>Burada kullanıcı mesajları yer alacak.</p>
        </div>
    );
}

export default MessagesPage;
