import React from 'react';
import './DashboardPage.css';

function DashboardPage() {
    return (
        <div className="dashboard-page">
            <h1>Kokpit</h1>
            <p>Burada kullanıcı kokpiti ile ilgili bilgiler yer alacak.</p>
        </div>
    );
}

export default DashboardPage;
